@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.inputStyle {
  width: 100% !important;
  height: 3.5rem !important;
  border-radius: 5px;
}
html {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

input {
  color-scheme: dark;
}
.grecaptcha-badge {
  visibility: hidden;
}

/* div::-webkit-scrollbar {
  display: none;
} */

.download-bg {
  background: url("./assets/background.png");
  background-size: cover;
  background-position: center;
}

.banner2-bg {
  background: url("./assets/banner2.png");
  background-size: cover;
  background-position: center;
}

.swiper-pagination-bullet {
  z-index: 999;
  background-color: #fc8300;
}

.swiper-pagination-bullet-active {
  width: 30px;
  height: 10px;
  border-radius: 20px;
}

.rdrCalendarWrapper {
  background: #202020 !important;
  color: "white" !important;
}

.rdrDayNumber span {
  color: white !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: gray !important;
}

.rdrDefinedRangesWrapper {
  background-color: #202020 !important;
  color: white !important;
  border-right: solid 1px #5a5a5a;
}

.rdrStaticRange {
  border-bottom: solid 1px #5a5a5a;
}

.rdrStaticRange:hover .rdrStaticRangeLabel {
  background: #5a5a5a !important;
}

.rdrStaticRangeSelected {
  color: #fc8300 !important;
}

.rdrDateDisplayWrapper {
  background-color: #202020 !important;
}
.rdrDateInput {
  border: none;
  overflow: hidden;
}

.rdrDateDisplayItemActive input {
  color: white;
  background-color: #fc8300;
  border: none;
}

.rdrDayToday .rdrDayNumber span::after {
  background-color: #fc8300 !important;
}

@media screen and (max-width: 428px) {
  html {
    font-size: 90%;
  }
}
