@media (min-width: 290px) and (max-width: 670px) {
    .teks10 {
        font-size: 10px;
    }
    .teks12 {
        font-size: 12px !important;
    }
    .teks14 {
        font-size: 14px !important;
    }
    .teks15 {
        font-size: 15px !important;
    }
    .hiddenmobile {
        display: none;
    }
    .padding1mobile {
        padding: 1px !important;
    }
}